.digital-marketing-internship {
    
    padding: 30px;
  }
  
  /* General Layout */
.internship-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    gap: 20px; /* Adds space between the text and image section */
    flex-wrap: wrap; /* Makes the layout responsive */
    animation: fadeIn 1s ease-out; /* Fade-in animation */
  }
  
  .text-section {
    flex: 1 1 45%; /* Takes up 45% width, adjusts with screen size */
    padding-right: 0px;
  }

  .register-btn {
    background-color: #121f27;
    color: white;
    font-size: 18px;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .register-btn:hover {
    background-color: #585b5d;
  }
  
  .text-section h2 {
    color: #141f2a;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .text-section p {
    color: #000000;
    font-size: 18px;
    line-height: 1.6;
    text-align: justify;
  }
  
  .image-section {
    flex: 1 1 45%; /* Takes up 45% width, adjusts with screen size */
 
    overflow: hidden;
   
    
    justify-content: center; /* Centers image in the container */
  }
  
  .internship-image {
    width: 100%;
    height: auto; /* Ensures the image scales proportionally */
   border-radius: 30px;
    
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .internship-info {
      flex-direction: column;
      align-items: center;
    }
  
    .text-section {
      flex: 1 1 100%;
      text-align: center; /* Center text on small screens */
    }
  
    .image-section {
      flex: 1 1 100%;
      margin-top: 20px;
    }
  }
  
  .why-choose-us1 {
    display: flex;
    justify-content: center;
    padding: 50px;
    background: linear-gradient(110.59deg, rgba(236, 207, 254, 0.4) 0%, rgba(255, 221, 221, 0.5) 46.79%, rgba(247, 255, 229, 0.7) 100%) /* Light gradient background */
  }
  
  .container1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
  }
  
  .image1 img { 
    max-width: 580px;
    height: 690px;
    border-radius: 8px;
  }
  
  .content1 {
    width: 90%;
    padding-left: 30px;
  }
  
  .content1 h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .content1 ul {
    list-style: none;
    padding: 0;
  }
  
  .content1 li {
    display: flex;
    align-items: flex-start;
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #555;
  }
  
  .content1 li .icon {
    margin-right: 20px;
    color: #007bff;
    font-size: 1.5rem;
  }
  
  .content1 li .text {
    flex: 1;
  }
  
  .content1 li .text strong {
    font-weight: bold;
    font-size: 1.4rem;
    color: #333;
  }
  
  .content1 li .text p {
    font-size: 1rem;
    color: #666;
    margin-top: 5px;
  }
  
  
  
  
  
  
  
  
  .testimonials {
    text-align: center;
    padding: 50px 20px;
  }
  
  .testimonials h3 {
    font-size: 36px;
    color: #2c3e50;
    margin-bottom: 40px;
    font-weight: bold;
  }
  
  .testimonial-cards {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    margin: 0 ;
  }
  
  .testimonial-card {
    background-color: #1b3e74;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid #ecf0f1;
    max-width: 320px;
    height: 310px;
    width: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .testimonial-card:hover {
    transform: translateY(-10px);
  }
  
  .testimonial-card p {
    color: #ffffff;
    font-size: 18px;
    line-height: 1.6;
  }
  
  .testimonial-card span {
    display: block;
    margin-top: 15px;
    color: #ffffff;
    font-style: italic;
    font-size: 16px;
  }
  
  @media (max-width: 768px) {
    .testimonial-cards {
      justify-content: center;
    }
  
    .testimonial-card {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .testimonials h3 {
      font-size: 28px;
    }
  
    .testimonial-card p {
      font-size: 16px;
    }
  }
  
 /* What You Will Learn Section */
/* What You Will Learn Section */
/* What You Will Learn Section */
.what-you-will-learn {
    text-align: center;
    padding: 50px 20px;
    background: #f4f4f4;
  }
  
  .learn-heading {
    font-size: 32px;
    color: #222;
    margin-bottom: 30px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .learn-heading::after {
    content: "";
    width: 80px;
    height: 4px;
    background: #9333ea;
    display: block;
    margin: 8px auto 0;
    border-radius: 2px;
  }
  
  .learn-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  /* Cards with Vibrant Gradient */
  .learn-item {
    background: linear-gradient(135deg, #192844, #2575fc); /* Purple to Blue Gradient */
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    width: 280px;
    text-align: center;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    border: 2px solid transparent;
    color: white;
    position: relative;
    overflow: hidden;
  }
  
  .learn-item h4 {
    font-size: 22px;
    margin-bottom: 10px;
    transition: color 0.3s;
  }
  
  .learn-item p {
    font-size: 16px;
    transition: color 0.3s;
  }
  
  /* Smooth Hover Effect */
  .learn-item:hover {
    transform: scale(1.08);
    background: linear-gradient(135deg, #000000, #000000); /* Warm Pink-Orange */
    box-shadow: 0 6px 12px rgba(255, 99, 71, 0.5);
  }
  
  /* Click Effect - Selection */
  .learn-item.selected {
    background: linear-gradient(135deg, #000000, #000000); /* Sunset Gradient */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
  }
  