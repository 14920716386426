.navbar-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Style the navbar-toggler to move to the left and have a cancel button style */
  .navbar-toggler {
    /* position: absolute; */
    left: 10px;
    top: 10px;
  }
  
  /* Logo styling */
  .logo-img {
    width: 50px;
    height: 80px;
  }
  
  .project-name {
    font-weight: bold;
    font-size: 20px;
  }
  
  /* Mobile menu animation */
  .collapse.show {
    transition: max-height 0.3s ease-in;
    max-height: 300px;
  }
  
  .nav-item {
    margin: 10px 0;
  }
  
  /* Override dropdown menu for better spacing */
  .dropdown-menu {
    padding: 10px;
  }


.logotransform {
    display: flex;
    align-items: center;
}

.logo-img {
    width: 78px;
    height: 55px;
    margin-right: 0px;
}

.project-name {
    font-size: 1.5rem;
    font-weight: bold;
}

/* Dropdown menu styling */
.nav-item .dropdown-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 1rem;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.nav-link {
    padding: 0.5rem 1rem;
    color: #000000;
    text-decoration: none;
}

.nav-link:hover {
    color: #007bff;
}

.navbar-nav .dropdown-item {
    padding: 0.5rem 1rem;
    color: #000000;
    text-decoration: none;
}

.navbar-nav .dropdown-item:hover {
    background-color: #f8f9fa;
    color: #007bff;
}

/* Mobile responsive view */
/* @media (max-width: 991px) {
    .logotransform {
        order: 0;
    }

    .navbar-nav {
        text-align: center;
    }

    .navbar-toggler {
        order: 1;
    }
} */

.bg-light {
  padding: 6px !important;
}