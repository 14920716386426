/* General Page Styling */
.internships-container {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

/* Banner Section */
.banner {
    background-image: url('./about.jpg'); /* Replace with your actual banner image */
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    position: relative;
}

/* Dark overlay for better text visibility */
.banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay effect */
}

/* Banner Content */
.banner-content {
    position: relative;
    z-index: 1;
    max-width: 80%;
}

.banner-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
}

.banner-subheading {
    font-size: 1.5rem;
    margin-top: 10px;
}

/* Cards Section */
.cards-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 20px;
    max-width: 100%;
    margin: auto;
    gap: 20px;
    justify-content: center;
}

.card {
    width: 400px;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.8s ease, box-shadow 0.3s ease;
    flex-shrink: 0;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.5);
}

/* Image Section */
.card-image {
    height: 500px;
    width: 400px;
    background-size: cover;
    background-position: center;
    position: relative;
}

/* Gradient applied only to image */
.card-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to top, rgba(13, 71, 161, 0.9), transparent);
    z-index: 1;
}

/* Card Title with Icon */
.card-title {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    font-size: 1.8rem;
    margin: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 10px;
}

.card-title h3 {
    margin: 0;
}

/* Font Awesome Icon styling */
.card-icon {
    font-size: 1.8rem;
    color: white;
}

/* Hover Content */
.card-hover-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8); /* Dark background */
    padding: 20px;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateY(100%);
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card:hover .card-hover-content {
    opacity: 1;
    transform: translateY(0);
}

/* Icon and Text Layout */
.card-icon-text {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left;
}

.card-icon {
    width: 40px;
    height: 40px;
}

/* White Text */
.card-hover-content p {
    font-size: 1.3rem;
    color: white;
    margin-bottom: 20px;
    text-align: justify;
}

/* Button */
.card-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.8s ease;
}

.card-button:hover {
    background-color: #0056b3;
}



/* Why Choose Us Section */
.whyy-choose-us {
    background-color: #f9f9f9; /* Light gray background */
    padding: 50px 20px;
    border-radius: 10px;
  }
  
  .whyy-choose-us .section-heading {
    font-size: 2.5rem;
    color: #2c3e50;
    font-weight: 600;
  }
  
  .why-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .why-card:hover {
    background-color: #0e151b;
    color: #fff;
    transform: translateY(-5px);
  }
  
  .why-card:hover .why-card-title,
  .whyy-card:hover .why-card-title i {
    color: #fff; /* Change text and icon color to white */
  }
  
  .why-card-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2c3e50;
  }
  
  .wh-card-title i {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 15px;
  }
  
  .why-card-text {
    font-size: 1.125rem;
    color: #34495e;
    line-height: 1.7;
  }
  
  .why-card:hover .why-card-text {
    color: #fff; /* Change the card text to white on hover */
  }
  
  @media (max-width: 768px) {
    .why-card {
      margin-bottom: 20px;
    }
  }
  


  /* Cards Section */
.cards-container {
    display: flex;
    flex-wrap: wrap; /* Allow the cards to wrap on smaller screens */
    padding: 20px;
    max-width: 100%;
    margin: auto;
    gap: 20px;
    justify-content: center;
}

/* Card Styling */
.card {
    width: 400px; /* Default width for larger screens */
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.8s ease, box-shadow 0.3s ease;
    flex-shrink: 0;
    margin-bottom: 20px; /* Add margin for spacing between cards */
}

/* Hover Effect */
.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.5);
}

/* Image Section */
.card-image {
    height: 500px;
    width: 100%; /* Make image take full width of the card */
    background-size: cover;
    background-position: center;
    position: relative;
}

/* Gradient applied only to image */
.card-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to top, rgba(13, 71, 161, 0.9), transparent);
    z-index: 1;
}

/* Card Title with Icon */
.card-title {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    font-size: 1.8rem;
    margin: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 10px;
}

.card-title h3 {
    margin: 0;
}

/* Font Awesome Icon styling */
.card-icon {
    font-size: 1.8rem;
    color: white;
}

/* Hover Content */
.card-hover-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8); /* Dark background */
    padding: 20px;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateY(100%);
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card:hover .card-hover-content {
    opacity: 1;
    transform: translateY(0);
}

/* Icon and Text Layout */
.card-icon-text {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left;
}

.card-icon {
    width: 40px;
    height: 40px;
}

/* White Text */
.card-hover-content p {
    font-size: 1.3rem;
    color: white;
    margin-bottom: 20px;
    text-align: justify;
}

/* Button */
.card-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.8s ease;
}

.card-button:hover {
    background-color: #0056b3;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .card {
        width: 100%; /* Make the cards take full width on smaller screens */
    }

    .card-image {
        height: 300px; /* Adjust image height for smaller screens */
    }

    .card-icon-text p{
        font-size: 1.0rem;
    }
    
    .cards-container {
        justify-content: flex-start; /* Align cards to the start for small screens */
    }
}
